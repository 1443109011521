<template>
	<div class='carousel-container' :class='classes' ref='carousel'>
	<div class='carousel' :style="{'overflow-x': noScroll ? 'hidden !important' : 'scroll'}">
		<slot />
	</div>
	<div id='controls' v-if='(lastButton || (numberOfSlides != 1)) && !noControls'>
		<div id='btn-prev'>
			<btn variant='arkly' label="btn-prev" :backgroundColor='buttonColor' width='72px !important' icon='arrow-left' @click='changeSlide(-1)' v-show="(current!=0)"/>
		</div>

		<div class='dot-container'>
				<div 
					v-show='numberOfSlides > 1'
					class='dot' 
					:class="dotClass(index)"
					v-for='(item, index) in slides'  
				/>
		</div>
		
		<div id='btn-next' v-if='!lastButton || ((current != numberOfSlides - 1) && (numberOfSlides > 1))'>
			<btn variant='arkly' label="btn-next" :backgroundColor='buttonColor' width='72px !important' icon='arrow-right' @click="changeSlide(1)" v-show='current != numberOfSlides - 1'/>
		</div>
		<div id='btn-next' v-else-if='lastButton'>
			<btn variant='arkly' label="btn-next-last" :backgroundColor='buttonColor' :width="isMobile ? '140px !important' : 'auto !important'" @click="changeSlide(1); $emit('lastButton')" v-show='current != numberOfSlides' > {{ lastButton }} </btn>
		</div>
	</div>
	<div id='control-replacement' style='background-color: transparent' v-else >
		<slot name='control-replacement' />
	</div>
</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import Button from '@/components/Button'
import { event } from 'vue-gtag'


export default {
	name: 'Carousel',
	props: {
		'width': {
			type: String,
		},
	},
	components: {
		'btn': Button,
	},
	props: {
		value: {
			type: Number,
			default: 0,
		},
		variant: {
			type: String,
			default: 'arkly',
			validator: function (value) {
				return ['arkly', 'modal'].indexOf(value) !== -1;
			},
		},
		floodRisk: {
			type: Number,
			default: 0,
		},
		floodZone:{
			type: String,
			default: 'N/A',
		},
		groundElevation: {
			type: Number,
		},
		floodElevation: {
			type: Number,
		},
		lastButton: {
			type: String,
		},
		noControls: {
			type: Boolean,
			default: false
		},
		noScroll: {
			type: Boolean,
			default: false
		},
		newSlide: {
			type: Number,
			default: undefined
		},
		advanceSlide: {
			type: Boolean,
			default: false,
		},
		restartCarousel: {
			type: Boolean,
		},
		validation: {
			type: Object,
		},
	},
	emits: ['restartedCarousel','hasValidationError','slideAdvanced','input','slideChanged','hasRestarted','closeCarousel'],
	mounted() {	
		this.show = true

		//console.log('restart state: ', this.restartCarousel)

		this.$refs.carousel.children[0].addEventListener('scroll', this.handleScroll)
		this.slides = this.$refs.carousel.children[0].children
		// Create the observer to watch the number of slides 
		this.observer = new MutationObserver(function(mutations) {
			// don't add observer when the carousel is not available
			if (this.$refs.carousel) {
				this.slides = this.$refs.carousel.children[0].children
				this.numberOfSlides = this.slides.length	
			}
		}.bind(this))

		// Setup the observer
		this.observer.observe(
			this.$refs.carousel.children[0],
			{ attributes: true, childList: true, characterData: true, subtree: true }
		)

		const delta = this.newSlide - this.current
		this.changeSlide(delta)
		//this.preventUserScroll()
	},
	beforeDestroy: function() {
		// Clean up
		this.observer.disconnect()
	},
	data() {
		return {
			current: this.value,
			direction: 1,
			transitionName: "fade",
			show: false,
			slides: undefined,
			numberOfSlides: undefined,
			observer: null,
			ticking: false,
			number: 0
		}
	},
	methods: {
		changeSlide(delta) {
			let validator = true
			// Check if advancing a step needs to be validated
			if (this.validation && delta > 0) {

				const page = this.slides[this.current]
				validator = Object.entries(this.validation).reduce((acc, [key, value]) => {	
					if (page['id'] == key) {
						return acc && Boolean(value)
					} else {
						return acc
					}
				}, true)
			}

			if (validator == true) {
				if (this.current == 0 & delta < 0) {
					this.$emit('closeCarousel', true)
				}
				const carousel = this.$refs.carousel.children[0]
				const width = carousel.offsetWidth
				carousel.scrollTo(carousel.scrollLeft + width * delta, 0)	
				this.$emit('slideAdvanced')
				this.$emit('hasValidationError', false)
			} else {
				this.$emit('hasValidationError', true)
			}
		},
		dotClass(slide) {
			if (slide == this.current) {
				return [ 'current-slide' ]
			} else {
				return []
			}
			return []
		},
		handleScroll(event) {	
			if (!this.ticking) {	
				//console.log('1')
				//console.log(this.oldScrollX,this.$refs.carousel.children[0].scrollLeft)
				//this.direction = this.oldScrollX - this.$refs.carousel.children[0].scrollLeft
				window.requestAnimationFrame(() => {
					//handle to carousel
					// Calculate Visible Slide

					const carousel = this.$refs.carousel.children[0]
					let position
					//console.log('1')
					//console.log(carousel)
					if (carousel.offsetWidth > 0) {
						position = (carousel.offsetWidth + carousel.scrollLeft)/(carousel.offsetWidth) - 1
					} else {
						position = 0
					}
					//console.log(position,carousel.offsetWidth,carousel.scrollLeft,this.current)
					this.current = Math.round(position)	
					this.ticking = false
				})
				this.ticking = true
			}
		},
		restart() {
			const carousel = this.$refs.carousel.children[0]
			const width = carousel.offsetWidth
			carousel.scrollIntoView(false)
			
		},
		preventUserScroll() {
			const preventScroll = (e) =>  {
				if (this.$refs.carousel && e.target == this.$refs.carousel) {
					//console.log(e)
					//this.$refs.carousel.scrollLeft(0)
					//e.preventDefault()
					//e.stopPropagation()
					return false
				}
			}
			if ( this.$refs.carousel) {
				this.$refs.carousel.addEventListener('wheel', preventScroll, {passive: false})
			}
		},
		isScrolledIntoView(el) {
			const parentWidth = el.parentElement.getBoundingClientRect().right
			var rect = el.getBoundingClientRect()
			var elemLeft = rect.right
			var elemRight = rect.left

			// Only completely visible elements return true:
			var isVisible = (elemLeft >= 0) && (elemRight <= parentWidth) 
			// Partially visible elements return true:
			//isVisible = elemTop < window.innerHeight && elemBottom >= 0;
			return isVisible
		}
	},
	computed: {
		classes() {
			if (this.variant == 'modal') {
				return ['modal']
			} else {
				return []
			}
		},
		buttonColor() {
			if (this.variant == 'modal') {
				return 'oat' 
			} else {
				return undefined 
			}
		},
		numOfSlides () {
			if (this.$refs.carousel) {
				return this.$refs.carousel.children[0].length
			}
		},
		...mapState(useConfigStore, ['colors', 'isMobile', ]),
		...mapState(useSurfStore, ['fullAddress']),
	},
	watch: {
		newSlide(newSlideNumber) {
			if (newSlideNumber) {
				const delta = newSlideNumber - this.current
				this.changeSlide(delta)
			}
		},
		advanceSlide(state) {
			if (state) {
				this.changeSlide(1)
			}
		},
		current(newSlideNumber, oldSlideNumber) {
			this.$emit('input', newSlideNumber)
			this.$emit('slideChanged', {
				'current': newSlideNumber,
				'numberOfSlides': this.numberOfSlides,
				'id': this.$refs.carousel.children[0].children[newSlideNumber].id
			})
		},
		restartCarousel(state) {
			if (state) {
				this.changeSlide(-this.current)
				this.$emit('hasRestarted', true)
			}	
		},
		fullAddress: function(newAddress) {
			//console.log(newAddress)
			this.changeSlide(-this.current)
		},
		noControls(newState) {
			if (newState) {
				this.preventUserScroll()
			}
		},
	}
}

</script>

<style lang="scss">
@import "@/style/arkly.scss";

.carousel-container {
	height: 100%;
	max-height: 100%;
}

.carousel-container .carousel {
	display: flex;
	overflow: scroll;
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
	overflow-x: scroll;
	grid-area: slide;
	max-width: 100% !important;
	//max-height: fit-content;
	// Hide Scrollbar
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */	
}

// Hide Scrollbar in Safari
.carousel::-webkit-scrollbar {
  display: none;
}


.carousel > * {
	height: auto;
	max-height: 100%;
	min-height: 200px;
	flex: 1 0 100% !important;
	padding: 20px;
	overflow: clip;
	scroll-snap-align: start !important;
}

#controls > .btn-container {
	width: auto !important;
}

#controls > #btn-next {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	width: 100%;
	grid-area: next;
}

#controls > #btn-prev {
	grid-area: prev;
}

.dot-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 25px;
	grid-area: dots;
}

.dot {
	height: 8px;
	width: 8px;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
}

.dot-container .current-slide {
	background-color: $yellow !important;
}

.carousel-container #controls {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: 
		" prev dots next ";
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 30px;
	min-width: 100%;
	width: 100%;
	max-width: 100%;
	height: 100px;
	grid-area: controls;
}

.modal #controls {	
	background-color: $oat;
}

.carousel-container #control-replacement {
	width: 100%;
	height: 100%;
	grid-area: controls;
}


.carousel-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 100px;
	grid-template-areas: 
		" slide    "
		" controls ";
	padding: 0;
	margin: 0;
	height: 100%;
	max-height: 100%;
	overflow-y: visible;
	position: relative;
	//max-width: $sidepanel-width;
}

@media screen and (max-width: $mobile-breakpoint) {

	.carousel-container .carousel {
		scroll-behavior: unset;
	}

	.carousel-container {
		overflow-y: scroll !important;
	}

	.carousel-container .carousel {
		overflow-y: scroll;	
	}

	.carousel-container #controls {
		padding: 30px 20px;
		min-width: 100%;
	}

	.dot-container {
		gap: 5px;
	}
}

</style>
